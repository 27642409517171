import { UnleashClient } from '@unleash/proxy-client-vue';

const getUnleashConfig = () => {
  const env = window.emblueConfig?.vueEnv || 'development';
  const url = window.emblueConfig?.unleashUrl || '';
  const clientKey = window.emblueConfig?.unleashClientKey || '';
  return {
    url: url,
    clientKey: clientKey,
    refreshInterval: 15,
    appName: 'emblue-inbox',
    environment: env === 'production' ? 'production' : 'development',
  };
};

export const unleashClient = new UnleashClient({
  ...getUnleashConfig(),
});

export const updateUnleashContext = user => {
  if (!user) return;

  unleashClient.updateContext({
    userId: user.id?.toString(),
    properties: {
      userEmail: user.email,
      chatwootAccountId: user.account_id?.toString(),
    },
  });
};

export const checkFlag = flagName => {
  return unleashClient.isEnabled(flagName, unleashClient.getContext());
};

export const initializeUnleash = async user => {
  if (!user?.id) {
    throw new Error('User is not logged in');
  }

  try {
    await unleashClient.start();
    updateUnleashContext(user);
    return true;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to initialize feature flag client:', error);
    return false;
  }
};

export const stopUnleash = async () => {
  try {
    if (unleashClient) await unleashClient.stop();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to stop feature flag client:', error);
  }
};
