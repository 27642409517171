import { frontendURL } from '../../../../helper/URLHelper';

const SettingsContent = () => import('../Wrapper.vue');
const TemplatesHome = () =>
  import('../../../../components/emblue/templates/EmblueTemplates.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/templates'),
      component: SettingsContent,
      props: {
        headerTitle: 'WHATSAPP_TEMPLATES.TEMPLATE_MGMT.HEADER.TITLE',
        icon: 'whatsapp',
        showNewButton: true,
      },
      children: [
        {
          path: '',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'templates_list',
          meta: {
            permissions: ['administrator', 'agent'],
          },
          component: TemplatesHome,
        },
      ],
    },
  ],
};
